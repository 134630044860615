import React from "react"
import {Router , Route} from "@reach/router"
// import Contact from "./pages/contact"
// import Services from "./pages/services"
import Commercial from "./services/commercial"
import Home from "./index"

// import Hydro from "../pages/services/hydro"
// import Descaling from "../pages/services/descaling"
// import CameraScoping from "../pages/services/camerascoping"
// import SewerCleaning from "../pages/services/sewer-cleaning"
// import SewerTracing from "../pages/services/sewer-line-tracing"
// import SewerRepair from "../pages/services/sewer-repair"

const IndexPage = () => (
    
      <Router>
        <Home exact path="/" />
        <Commercial path="commercial" />
        {/* <Services path="services" /> */}

        {/* <Hydro path="hydro" />
        <Descaling path="descaling" />
        <CameraScoping path="camerascoping" />
        <SewerCleaning path="sewercleaning" />
        <SewerTracing path="sewertracing" />
        <SewerRepair path="/services/sewerrepair" /> */}
      </Router>
  
  )

  export default IndexPage
